<template>
  <section>
    <section class="ConOfproductsHead">
      <div class="understandsHead"></div>
      <div class="conOfprodHeadTxt">
        <h1>What are muscle pains?</h1>
        <div class="mt-2">
          Muscle pains and aches are common and can involve more than one
          muscle.
        </div>
        <div class="mt-2">
          Muscle pain also can involve ligaments, tendons, and fascia.
        </div>
        <div class="mt-2">
          Fascias are the soft tissues that connect muscles, bones, and organs.
        </div>
        <div class="mt-2">
          Muscle pain is most often related to tension, overuse, or muscle
          injury from exercise or hard physical work.
        </div>
        <div class="mt-2">
          The pain tends to involve specific muscles and starts during or just
          after the activity. It is often obvious which activity is causing the
          pain.
        </div>
      </div>
    </section>

    <section class="prone-sec">
      <v-container class="h-100">
        <v-row class="h-100">
          <v-col md="7" cols="12">
            <div class="conOfheadTxtRev">
              <div class="titleTxtpurple my-2">
                Who are more prone to muscle pain?
              </div>

              <div class="conOfline d-flex jusify-center">
                <span class="smallthinLine"></span>

                <span class="thickLine"></span>
                <span class="thinLine"></span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="justify-space-around py-5">
          <v-col md="4" cols="12">
            <img src="@/assets/img/uPain01.png" class="img-fluid cardImg" />
            <p class="uPainTxtHead my-1">Physical demands.</p>
            <div class="uPainsubTxt">
              such as lifting and working in awkward postures, place additional
              stress on your muscles and joints.
            </div>
          </v-col>
          <v-col md="4" cols="12" class="cardImgMob">
            <div class="scene scene--card mb-4">
              <div class="card" v-bind:class="{ flipme: cardOne == 'flipped' }">
                <div class="card__face card__face--front">
                  <img src="@/assets/img/uPain02.png" class="img-fluid" />
                </div>
                <div class="card__face card__face--back">
                  <div>
                    More than 90% of the elderly living in the community are
                    experiencing muscle pain.
                    <br />
                    The most common painful musculoskeletal conditions among
                    older adults are osteoarthritis, low back pain,
                    fibromyalgia, chronic shoulder pain, knee pain, and previous
                    fracture sites.
                    <br />
                    The impact of muscle pain in elderly is a cycle of disuse
                    and inactivity. This cycle in turn leads to a further
                    reduction in function, accompanying psychological effects
                    and decreased quality of life.
                  </div>
                </div>
              </div>
            </div>

            <p class="uPainTxtHead my-1">Older adults</p>
            <div class="uPainsubTxt">
              More than 90% of the elderly living in the community are
              experiencing muscle pain.
            </div>
            <div
              class="readYellow"
              @click="
                cardOne == 'start' ? (cardOne = 'flipped') : (cardOne = 'start')
              "
            >
              Read More <span>&#8594;</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="justify-space-around py-5 mb-5">
          <v-col md="4" cols="12" class="cardImgMob">
            <div class="scene scene--card">
              <div
                class="card"
                v-bind:class="{ flipme: cardOne2 == 'flipped2' }"
              >
                <div class="card__face card__face--front">
                  <img src="@/assets/img/uPain03.png" class="img-fluid" />
                </div>
                <div class="card__face card__face--back">
                  <div>
                    Examples include cramping (a short and intense contraction
                    of the muscles), soreness and stiffness (a natural result of
                    muscle inflammation), spasms (a more severe kind of muscle
                    stiffness), muscle tears (partial breakage of a muscle and
                    its surrounding envelope), pulled or strained muscles (large
                    tears), and full-thickness muscle tears (the most severe
                    kind of muscle tear).
                  </div>
                </div>
              </div>
            </div>

            <p class="uPainTxtHead my-1">
              Athletes are also more likely to experience muscle pain than other
              people.
            </p>
            <div class="uPainsubTxt">
              Examples include cramping (a short and intense contraction of the
              muscles), soreness and stiffness
            </div>
            <div
              class="readYellow"
              @click="
                cardOne2 == 'start2'
                  ? (cardOne2 = 'flipped2')
                  : (cardOne2 = 'start2')
              "
            >
              Read More <span>&#8594;</span>
            </div>
          </v-col>

          <v-col md="4" cols="12" class="mt-mob">
            <img src="@/assets/img/uPain-04.png" class="img-fluid cardImg" />
            <p class="uPainTxtHead my-1">
              Working continuously for long hours puts you at higher risk for
              pain or discomfort
            </p>
            <div class="uPainsubTxt">
              Upper extremities (hands, arms) Lower extremities (feet, knees,
              hips) Neck, shoulders, and back
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>
<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "understandingPain",
  components: {},

  data() {
    return {
      CurrentTab: "Sprays",
      cardOne: "start",
      cardOne2: "start2",
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      settingsReviews: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        touchThreshold: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      videoOptions: {
        autoplay: false,
        muted: false,
        controls: true,
        preload: "auto",
        sources: [
          {
            src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
            type: "video/mp4",
          },
        ],
      },
      creams: [
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Move On Cream",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache ",
        },
        {
          img: require("@/assets/img/Move On Freez Gel-min.png"),
          title: "Move On Freez Gel",
          dec: "Gel for rapid relief with cooling effect targeting young people who are exposed to accidental injuries and muscle aches",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Move On Cream",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache ",
        },
      ],
      reviews: [
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Ahmed Mohamed",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache ",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Ahmed Mohamed",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Ahmed Mohamed",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
      ],
      sprays: [
        {
          img: require("@/assets/img/Move on freeze spray-min.png"),
          title: "Move on freeze spray",
          dec: "Freeze spray for athletes, fitness enthusiasts, and sporty people who require cooling effect to relieve muscle spasm/ cramps and aches, easy to carry and apply within seconds , provides a highly pressurized force of cooling menthol that quickly numbs the pain, low temperatures immediately relieve swelling and pain",
        },
        {
          img: require("@/assets/img/Move on Spray-min.png"),
          title: "Move on spray",
          dec: "Spray for easy application for those who do not tolerate or do not like rubbing cream, it also provides instant relief, Sprays can be excellent for hard-to-reach spots like your back.",
        },
        {
          img: require("@/assets/img/Move on Spray-min.png"),
          title: "Move on spray",
          dec: "Spray for easy application for those who do not tolerate or do not like rubbing cream, it also provides instant relief, Sprays can be excellent for hard-to-reach spots like your back.",
        },
      ],
      patches: [
        {
          img: require("@/assets/img/Move On Freeze Patch-min.png"),
          title: "Pain Type",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
        {
          img: require("@/assets/img/Move On Hot Patch-min.png"),
          title: "Move On Hot Patch",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
        {
          img: require("@/assets/img/Move On Freeze Patch-min.png"),
          title: "Pain Type",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
      ],
    };
  },

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./understand";
</style>
